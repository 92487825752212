import React from 'react';
import { Row, Col } from 'antd';

import Item from './Item';

import './index.less';

function Listing({ section, type, image, image_title, image_alt, title, content, items }) {
  const children = items.sort((a, b) => a.sorter - b.sorter).map((item, index) => {
    return (
      <Item key={item.uid} {...{ type, index, ...item }} />
    )
  });

  let listLayout = (
    <div>
      <h1>{title}</h1>
      <div className="listing__title-border"></div>
      <div dangerouslySetInnerHTML={{ __html: content }}></div>
      <ul className={`listing__${type}`}>
        {children}
      </ul>
    </div>
  );

  if (type === 'home-client-list') {
    listLayout = (
      <div className={`listing__${type}`}>
        <Row>
          <Col span="24" dangerouslySetInnerHTML={{ __html: content }} />
        </Row>
        <Row align="middle">
          {children}
        </Row>
      </div>
    );

  } else if (type === 'home-service-list') {
    listLayout = (
      <div className={`listing__${type}`}>
        <h2 dangerouslySetInnerHTML={{ __html: title }} />
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
        <Row type="flex" justify="center">
          {children}
        </Row>
      </div>
    );
  } else if (type === 'home-product-list') {
    listLayout = (
      <div className={`listing__${type}`}>
        <h2 dangerouslySetInnerHTML={{ __html: title }} />
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <Row type="flex" justify="space-between">
          {children}
        </Row>
      </div>
    );
  } else if (type === 'about-portfolio-list') {
    listLayout = (
      <div className={`listing__${type}`}>
        <h2 dangerouslySetInnerHTML={{ __html: title }} />
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <Row>
          {children}
        </Row>
      </div>
    );
  } else if (type === 'about-solution-list') {
    listLayout = (
      <div className={`listing__${type}`}>
        <h2 dangerouslySetInnerHTML={{ __html: title }} />
      </div>
    );
  } else if (type === 'about-solution-list-veks') {
    listLayout = (
      <div className={`listing__${type}`}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <Row>
          {children}
        </Row>
      </div>
    );
  } else if (type === 'about-solution-list-netuno') {
    listLayout = (
      <div className={`listing__${type}`}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <Row>
          {children}
        </Row>
      </div>
    )
  } else if (type === 'about-solution-list-crm') {
    listLayout = (
      <div className={`listing__${type}`}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <Row>
          {children}
        </Row>
      </div>
    )
  } else if (type === 'about-solution-list-ask2me') {
    listLayout = (
      <div className={`listing__${type}`}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <Row>
          {children}
        </Row>
      </div>
    )
  } else if (type === 'about-solution-list-formation') {
    listLayout = (
      <div className={`listing__${type}`}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <Row>
          {children}
        </Row>
      </div>
    )
  } else {
    listLayout = (
      <div className={`listing__${type}`} align={"middle"}>
        {children}
      </div>
    );
  }

  return (
    <section className="listing">
      {listLayout}
    </section>
  );
}

export default Listing;
